import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import InputField from '../../components/forms/InputField';
import Button from '../../components/elements/Button';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import { Link, useNavigate } from 'react-router-dom';
import Toast from '../../components/forms/Toast';
import Image from '../../components/images/Image';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false); 

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/api/check-auth', {
          method: 'GET',
          credentials: 'include'  // Include the JWT cookie in the request
        });

        if (response.ok) {
          // User is authenticated, navigate to the team select page
          navigate('/role');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };

    checkAuth();
  }, [navigate]);
  
  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('/api/login', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(values)
      });
  
      // Check if the response is OK (status code 200-299)
      if (response.ok) {
          const data = await response.json();
          console.log('Login successful:', data.message);
          setError(null); // Reset error on success
          navigate('/role'); // Navigate to role on successful login
      } else {
          // Handle non-200 status codes
          const errorData = await response.json();
          throw new Error(errorData.message || 'somethingWentWrong'); // Throw error to trigger catch block
      }
  } catch (error) {
      setError(error.message); // Set error message in state
      setShowToast(true); // Show toast notification when there's an error
      console.error('Login error:', error.message);
  }
  
};

  return (
    <Screen backgroundImage={require('./../../assets/images/login.png')}>
      <div className="center">
        <Card style={{width: '18rem', opacity: '0.9'}} mx='0'>
          <Title>{t("login")}</Title>
              <form onSubmit={onSubmit}>
                <InputField type="text" name="email" label={t("email")} placeholder={t("enterEmail")} required />
                <InputField type="password" name="password" mb="1" label={t("password")} placeholder={t("enterPassword")} required />

                <div className='text-start mb-3'><Link to="/forgot-password" className='link-active'>
                  {t("forgotPassword")}
                </Link></div>
                
                <Button mb="2" type="submit">{t("login")}</Button>
              </form>
    
          {/* <Button theme='primary' to="/register" >{t("register")}</Button> */}

          <Toast showToast={showToast} setShowToast={setShowToast} message={t(error)} />
        </Card>
        </div>

      <div className='bottom' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '-1rem' }}>
          <Card style={{opacity: '0.8'}} p='0'>
            <Image url={require('./../../assets/images/logo_text.png')} to={'https://tornea.net'} className='mx-5' isSquare={false} style={{width: '8rem', height: '4rem'}} />
            <div className='text-secondary'>Alpha v1.0</div>
          </Card>
      </div>
    </Screen>
  );
  

  
};


export default Login;



import React, { useState } from 'react';
import PropTypes from 'prop-types';

const styles = {
  Label: {
    color: '#6b7280',
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: '17px',
    textAlign: 'left !important'
  },
};

const CheckboxField = ({ name, label, className, mb='3', checked }) => {
  const [checkValue, setChecked] = useState(checked);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={`form-check ${className} mb-${mb} text-start`}>
      <input 
        type="checkbox" 
        className="form-check-input" 
        id={name} 
        name={name} 
        checked={checkValue}
        onChange={handleChange} 
      />
      <input 
        type="hidden" 
        name={name} 
        value={checkValue ? 'on' : 'off'} 
      />
      <label className="form-check-label" htmlFor={name} style={styles.Label}>
        {label}
      </label>
    </div>
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default CheckboxField;
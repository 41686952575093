import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Image from '../../components/images/Image';
import Icon from '../../components/common/Icon';
import { Link } from 'react-router-dom';
import Card from '../../components/elements/Card';
import Loading from '../../components/common/Loading';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/house', setUserInfo, setLoading, setError, ["selectedHouseId"]);
  }, []);

  return (
    <Base activeNav="home">
      <Card>
        <Title style={{ color: userInfo && userInfo.color }}>
          {userInfo && userInfo.name}
        </Title>
        {userInfo && userInfo.team_count} {t('teams')}
      </Card>

        {userInfo && userInfo.teams && (
          <div className='row d-flex justify-content-center g-0'>
            {userInfo.teams.map((team) => (
              <div className='col d-flex justify-content-center mb-3'>
                <Link to="/team" onClick={() => localStorage.setItem('otherTeamId', team.id)} style={{ textDecoration: 'none' }}>
                  <Image size="10.5rem" url={team.photo_url ? team.photo_url : undefined}>
                    <div className="p-2 image-caption">
                      <Title heading="6" my="0">{team.name}</Title> 
                      {team.company_name}
                    </div>
                  </Image>
                </Link>
              </div>
            ))}
            
          </div>
        )}
    </Base>
  );
};

export default Page;
import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Back from '../../components/common/Back';


const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/leaderboard', setUserInfo, setLoading, setError, ["selectedEventId"]);
  }, []);

  return (
    <Base activeNav="home">
        <Back to='/home' />
        <Title my='0' className='mt-4 mb-2'>{t('leaderboard')}</Title>
        <div className='px-3 text-secondary mb-3'>{t('leaderboardQuarters', { quarter: userInfo?.leaderboard_quarter})}</div>

        {userInfo?.teams.map((team, index) => (
          <Card key={team.id} to="/team" onClick={() => localStorage.setItem('otherTeamId', team.id)} className={`${team.id === userInfo.user_team_id ? 'btn-secondary' : ''}`}>
            <div className='justify-content-between d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                <div>
                  <Image size="4rem" className='me-2' url={team.photo_url ? team.photo_url: undefined} />
                </div>
                <div className='d-flex flex-column text-start'>
                  <div><b>{team.name}</b></div>
                  <div>{team.company_name}</div>
                  <div className='text-secondary'>{team.points} {t('points')} </div>
                </div>
              </div>
              <div className='me-2 underline-secondary' style={{ fontSize:'16px' }}>
                <b>{index + 1}</b>
              </div>
            </div>
          </Card>
        ))}

    </Base>
  );
};

export default Page;
import React from 'react';
import { useTranslation } from 'react-i18next';
import Screen from '../components/common/Screen';
import NavBar from '../components/common/NavBar';

const Base = ({ children, activeNav, color }) => {
  return (
    <Screen color={color} >
      <div className="top">
        {children}
      </div>
      <div className="bottom">
        <NavBar active={activeNav} />
      </div>
    </Screen>
  );
};

export default Base;
// Back.js
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';

const Back = ({ to }) => {
  return (
    <div style={{ position: 'absolute' }} className='p-2'>
      <Link to={to} style={{ color: 'inherit', textDecoration: 'none' }}>
        <Icon name='angle-left' />
      </Link>
    </div>
  );
};

export default Back;
import React from 'react';

const SameLine = ({ children, className }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }} className={className}>
      {children}
    </div>
  );
};

export default SameLine;
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const styles = {
  TextArea: {
    width: '100%',
    border: '1px solid #d1d5db',
    boxSizing: 'border-box',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    color: '#94a3b8',
    fontFamily: 'Poppins',
    lineHeight: '1.5',
    outline: 'none',
    fontSize: '14px',
  },
  Label: {
    color: '#6b7280',
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: '17px',
    textAlign: 'left !important'
  },
};

const TextArea = ({
  name,
  label,
  placeholder = 'Write something...',
  rows = 3,
  className = '',
  value = '',
  onChange,
}) => {
  const [textValue, setTextValue] = useState(value);

  const handleChange = (e) => {
    setTextValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={`textarea-field ${className} mb-3 text-start`}>
      <label htmlFor={name} style={styles.Label}>{label}</label>
      <textarea
        style={styles.TextArea}
        placeholder={placeholder}
        className="form-control"
        name={name}
        rows={rows}
        value={textValue}
        onChange={handleChange}
      />
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextArea;
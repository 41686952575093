import React, { useState, useRef, useEffect } from 'react';
import Image from '../images/Image';
import { Link } from 'react-router-dom';

const ImageInput = ({ onUpload, url, caption }) => {
  const [imageSrc, setImageSrc] = useState(url); // Initially use the provided image URL
  const fileInputRef = useRef(null); // To reference the hidden file input

  // Update the image preview when the URL changes
  useEffect(() => {
    if (url) {
      setImageSrc(url); // Update the image preview with the new URL passed as a prop
    }
  }, [url]); // Re-run this effect whenever the `url` prop changes

  // Handle file input change and trigger upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result); // Update image preview locally
      };
      onUpload(file); // Trigger the external `onUpload` function to handle the upload
    }
  };

  // Trigger file input when the user clicks the link
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      {/* Always show the image */}
      <Image 
        size='5rem' 
        className='mb-2 circle' 
        url={imageSrc || require('./../../assets/images/user.png')} // Show existing image or default one
      />

      {/* Hidden file input */}
      <input 
        type="file" 
        accept="image/*" 
        ref={fileInputRef} 
        onChange={handleFileChange} 
        style={{ display: 'none' }} // Hide the file input
      />

      {/* Link to trigger file input */}
      <Link 
        to="#" 
        style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} 
        onClick={triggerFileInput}
      >
        <b>{caption}</b>
      </Link>
    </div>
  );
};

export default ImageInput;

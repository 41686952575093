import React from 'react';
import { NavLink } from 'react-router-dom';
import Card from '../elements/Card';
import Icon from './Icon';


const NavBar = ({ active = 'home' }) => {
    return (
        <div className='bottom'>
            <Card mb="0" mx="0" p="2">
                <div className='row'>
                {/* <NavLink to="/feed" className="col-4 mt-1 text-center" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', p: '0' }}>
                  <div className={`col-4 mt-1 text-center ${active === "feed" ? 'active' : ''}`}>
                    <Icon name="bell" />
                  </div>
                </NavLink> */}
                
                <NavLink to="/home" className="col-6 mt-1 text-center" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', p: '0'  }}>
                  <div className={`col-6 mt-1 text-center ${active === "home" ? 'active' : ''}`}>
                    <Icon name="home" />
                  </div>
                </NavLink>
                
                <NavLink to="/profile" className="col-6 mt-1 text-center" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', p: '0'  }}>
                  <div className={`col-6 mt-1 text-center ${active === "profile" ? 'active' : ''}`}>
                    <Icon name="user" />
                  </div>
                </NavLink>
                </div>
            </Card>
        </div>
    );
  };

export default NavBar;
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  ImageContainer: {
    borderRadius: '8px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    backgroundColor: 'white',
  },
};

const Image = ({
  url = require('./../../assets/images/user.png'),
  size = '10rem',
  to = null,
  children,
  className,
  onClick,
  contain = false,
  isSquare = true,
  style = {},
}) => {
  const imageStyle = {
    ...styles.ImageContainer,
    ...style,
    backgroundImage: `url(${url})`, // Use the URL without cache-busting
    backgroundSize: contain ? 'contain' : 'cover',
  };

  if (isSquare) {
    imageStyle.width = size;
    imageStyle.height = size;
  }

  const image = (
    <div
      style={imageStyle}
      className={className}
      onClick={onClick}
    >
      {children}
    </div>
  );

  return to ? (
    <Link to={to} className='link'>
      {image}
    </Link>
  ) : (
    image
  );
};

export default Image;

// src/pages/NotFound.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/elements/Button';
import { useTranslation } from 'react-i18next';
import Screen from '../components/common/Screen';
import Center from '../components/css/Center';
import Image from '../components/images/Image';
import Title from '../components/common/Title';

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

  return (
    <Screen color='white'>
      <div className='center'>
        <div className='px-3'>
          <Center><Image url={require('./../assets/images/not-found.png')} className='my-4' /></Center>
          <Title heading='1'>{t('404')}</Title>
          <p>{t('pageDoesNotExist')} </p>
          <Button to='/' className='mt-2'>
            {t('backToHome')}
          </Button>
        </div>
      </div>
    </Screen>
  );
};


export default NotFound;

import React, { useState, useEffect } from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Screen from '../../components/common/Screen';
import Button from '../../components/elements/Button';
import Icon from '../../components/common/Icon';
import Left from '../../components/css/Left';
import Image from '../../components/images/Image';
import { fetchUserInfo } from '../../utils';
import Back from '../../components/common/Back';
import { Link, useNavigate } from 'react-router-dom';
import InputField from '../../components/forms/InputField';
import Card from '../../components/elements/Card';
import Center from '../../components/css/Center';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/add-players', setUserInfo, setLoading, setError, []);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && userInfo.players && userInfo.players.length > 0) {
      if (userInfo.players.length === userInfo.players[0].max_players) {
        navigate('/players');
      }
    }
  }, [userInfo, navigate]);
  

  const addPlayer = async (playerId) => {
    const token = localStorage.getItem('token');

    const response = await fetch('/api/add-player', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({playerId})
    });

    const data = await response.json();
    if (response.ok) {
        window.location.reload();
    } else {
        console.error(data.message);
    }
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase()); // Convert to lowercase for case-insensitive matching
  };

  // Filter the teams based on the search query
  const filteredEmployees = userInfo?.employees?.filter((employee) =>
    employee.full_name.toLowerCase().includes(searchQuery)
  );

  return (
    <Screen p='3'>
        <div className='top'>   
            <Back to='/players' />
            <Title>{t('addPlayers')}</Title> 
            
            {userInfo && userInfo.players && userInfo.players.length > 0 && (
          <div className='justify-content-center' >
            <Left><Title heading='6' my='2' className='mt-2'>
                {t('yourTeam')} 
                {userInfo && <> ({userInfo.players.length}/{userInfo.players[0].max_players}) </>}
            </Title></Left>
            {userInfo.players.map((player) => (
                <div className="dropdown" key={player.id}>
                  <Button p='1' mb="1" className={`${player.id === userInfo.user_id ? 'btn-outline-secondary' : ''}`}  theme='light' dataBsToggle="dropdown" ariaExpanded={false} >
                      <div className='row'>
                      <div className='col-3 d-flex align-items-center'>
                        <Image url={player.photo_url ? player.photo_url : require('./../../assets/images/user.png')} size='3rem' className='circle mb-1' />
                      </div>

                      <div className='col-8 d-flex align-items-center'>
                      <Left style={{marginLeft: '-1.5rem'}}>
                          {player.is_captain && (<Icon name='user-crown' pack='sr' className='me-1' theme='secondary' />)}
                          <b>{player.full_name}</b>
                        </Left>
                      </div>
                      
                      <div className='col-1 d-flex align-items-center'>
                        <Icon name='menu-dots-vertical' theme='primary' style={{marginLeft: '-0.8rem'}} />
                      </div>

                      </div>
                  </Button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link className="dropdown-item" to='/public-profile' onClick={() => localStorage.setItem('otherUserId', player.id)}>
                        <Icon name='user' className='me-1' />
                        {t("viewProfile")} 
                        </Link>
                      </li>
                    </ul>
                </div>
            ))}
          </div>
        )}

        

        {userInfo && userInfo.employees && userInfo.employees.length > 0  && (
          <div className='justify-content-center' >
            <hr className="mb-2" />
            <Left><Title heading='6' my='2' className='mt-2'>
                {t('available')}
                {userInfo && <> ({userInfo.employees.length}) </>}
            </Title></Left>
            
            <div className='row align-items-center'>
                <div className='col-1' style={{ marginTop: '-0.7rem' }}>
                    <Icon name="search" />
                </div>

                <div className='col-11'>
                <InputField placeholder={t('searchPlayers')} 
                name='searchTeams'
                value={searchQuery} // Bind the input value to the state
                onChange={handleSearchChange} // Update state on input change
                >
                    <Icon name="employees" />
                </InputField>
                </div>
            </div>

            {filteredEmployees.map((player) => (
                <div className="dropdown" key={player.id}>
                  <Button p='1' mb="1" className={`${player.is_captain ? 'btn-outline-secondary' : ''}`}  theme='light' dataBsToggle="dropdown" ariaExpanded={false} >
                      <div className='row'>
                      <div className='col-3 d-flex align-items-center'>
                        <Image url={player.photo_url ? player.photo_url : require('./../../assets/images/user.png')} size='3rem' className='circle mb-1' />
                      </div>

                      <div className='col-8 d-flex align-items-center'>
                        <Left style={{marginLeft: '-1.5rem'}}>
                          {player.is_captain && (<Icon name='user-crown' pack='sr' className='me-1' theme='secondary' />)}
                          <b>{player.full_name}</b>
                        </Left>
                      </div>
                      
                      <div className='col-1 d-flex align-items-center'>
                        <Icon name='menu-dots-vertical' theme='primary' style={{marginLeft: '-0.8rem'}} />
                      </div>

                      </div>
                  </Button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link className="dropdown-item" to='/public-profile'  onClick={() => localStorage.setItem('otherUserId', player.id)}>
                        <Icon name='user' className='me-1' />
                        {t("viewProfile")} 
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={() => addPlayer(player.id)}>
                          <Icon name='person-circle-plus' className='me-1' theme='secondary' />
                          {t("addPlayer")} 
                        </Link>
                      </li>
                      
                    </ul>
                </div>
            ))}
          </div>
        )}



            

            {userInfo && userInfo.other_players && userInfo.other_players.length > 0 && (
                
            <div className='justify-content-center' >
                <hr className="mb-2" />
                <Left><Title heading='6' my='2' className='mt-2'>
                    {t('unavailable')}
                    {userInfo && <> ({userInfo.other_players.length}) </>}
                </Title></Left>
                {userInfo.other_players.map((player) => (
                    <div className="dropdown" key={player.id}>
                    <Button p='1' mb="1" className={`${player.is_captain ? 'btn-outline-secondary' : ''}`}  theme='light' dataBsToggle="dropdown" ariaExpanded={false} >
                        <div className='row'>
                        <div className='col-3 d-flex align-items-center'>
                            <Image url={player.photo_url ? player.photo_url : require('./../../assets/images/user.png')} size='3rem' className='circle mb-1' />
                        </div>

                        <div className='col-8 d-flex align-items-center'>
                            <Left style={{marginLeft: '-1.5rem'}}>
                            {player.is_captain && (<Icon name='user-crown' pack='sr' className='me-1' theme='secondary' />)}
                            <b>{player.full_name}</b>
                            </Left>
                        </div>
                        
                        <div className='col-1 d-flex align-items-center'>
                            <Icon name='menu-dots-vertical' theme='primary' style={{marginLeft: '-0.8rem'}} />
                        </div>

                        </div>
                    </Button>
                        <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                            <Link className="dropdown-item" to='/public-profile'  onClick={() => localStorage.setItem('otherUserId', player.id)}>
                            <Icon name='user' className='me-1' />
                            {t("viewProfile")} 
                            </Link>
                        </li>
                        </ul>
                    </div>
                ))}
            </div>
            )}

        </div>

        <div className='bottom'>
          <div className="px-3 pt-3" style={{ backgroundColor: 'white' }} >
            <Button to="/players" className='mb-3'>
              {t("done")}
            </Button>
          </div>
        </div>

    </Screen>
  );
};

export default Page;
import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';

const Page = () => {
  const { t } = useTranslation();

  return (
    <Base activeNav="user">
        <Title>{t('')}</Title>

    </Base>
  );
};

export default Page;
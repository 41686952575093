import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Button from "../../components/elements/Button";
import Image from "../../components/images/Image";
import SameLine from "../../components/css/SameLine";
import Screen from "../../components/common/Screen";
import Title from "../../components/common/Title";

const languages = [
  {code: "en", lang: "English"},
  {code: "fr", lang: "Français"},
  {code: "nl", lang: "Nederlands"},
  {code: "ar", lang: "عربي"},
];

const LanguageSelector = () => {
  const {i18n, t} = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const images = require.context('./../../assets/images/languages', false, /\.(png)$/);


  return (
    <Screen color="white" p="3">
      <div className="top">
        <Title my="4"> {t("chooseLanguage")}</Title>

        {languages.map((lng) => {
          const imgSrc = images(`./${lng.code}.png`)
          return (
            <Button 
              theme='light' 
              key={lng.code} 
              onClick={() => changeLanguage(lng.code)} 
              className={lng.code === i18n.language ? "active-border" : ""} 
              align="start"
              p="3"
              mb="2"
            >
              <SameLine>
                <Image url={imgSrc} size="24px" className='mx-2' />
                {lng.lang}
              </SameLine>
            </Button>
          );
        })}
      </div>
      <div className="bottom">
        <div className="px-3">
          <hr className="mb-3" />
          <Button to="/profile" className='mb-3'>
            {t("done")}
          </Button>
        </div>
      </div>
    </Screen>
    
  );
};

export default LanguageSelector;
import React from 'react';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Image from '../../components/images/Image';
import Card from '../../components/elements/Card';
import Icon from '../../components/common/Icon';
import Center from '../../components/css/Center';
import Back from '../../components/common/Back';

const Battles = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/battles', setUserInfo, setLoading, setError, []);
  }, []);

  return (
    <Base activeNav="home">
        <Back to='/home' />

        <Title>{t('challenges')}</Title>
        {userInfo?.battles?.length > 0 ? (
          <div className='row d-flex justify-content-center g-0'>
              {userInfo.battles.map((battle) => (
                  <div key={battle.id} className='col d-flex justify-content-center mb-3'>
                      <Image size="11rem" url={battle.photo_url ? battle.photo_url : undefined} onClick={() => localStorage.setItem('selectedBattleId', battle.id)} to="/battle" className='outline-light'>
                          <Center>
                              <div className={`image-top px-3 py-1 ${battle.is_team_joker ? 'bg-secondary' : ''}`}>
                                  <b>
                                      <Icon name="star" pack='sr' className="me-2" theme='light' />
                                      {battle.team_count}
                                  </b>
                              </div>
                          </Center>
                          <div className="p-2 image-caption">
                              <Title heading="6" my="0">{battle.name}</Title>
                          </div>
                      </Image>
                  </div>
              ))}
          </div>
      ) : (
          <div className='text-center text-muted'>No battles available</div>
      )}


    </Base>
  );
};

export default Battles;
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const styles = {
  Input: {
    width: '100%',
    border: '1px solid #d1d5db',
    boxSizing: 'border-box',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    color: '#94a3b8',
    fontFamily: 'Poppins',
    lineHeight: '42px!important',
    outline: 'none',
    fontSize: '14px',
  },
  Label: {
    color: '#6b7280',
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: '17px',
    textAlign: 'left !important'
  },
};

const SelectField = ({
    name, 
    label, 
    children, 
    className,
    style,
    mb = '3',
    px = '2',
    value
}) => {
  const [selectValue, setValue] = useState(value);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className={`form-group ${className} mb-${mb} text-start`}>
      <label htmlFor={name} style={styles.Label}>{label}</label>
      <select 
        className="form-control" 
        id={name} 
        name={name} 
        style={styles.Input} 
        value={selectValue} 
        onChange={handleChange}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            selected: child.props.value === selectValue,
          })
        )}
      </select>
    </div>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
};

export default SelectField;
import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ 
  name, 
  pack = 'br', 
  className,
  style={}, 
  theme = 'primary'
}) => {
  return <i className={`fi fi-${pack}-${name} ${className} ico-${theme}`} style={style}></i>;
};
Icon.propTypes = {
  name: PropTypes.string,
  pack: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
import React, { useEffect, useState } from 'react';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Button from '../../components/elements/Button';
import { useTranslation } from 'react-i18next';
import { fetchUserInfo, formatDate } from '../../utils';
import { logout } from '../../utils';
import Icon from '../../components/common/Icon';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/role', setUserInfo, setLoading, setError);
  }, []);

  const role_dict = {
    "supporter": {color: "#5AC8FA", icon: "following", to: "/event-select"},
    "player": {color: "#FF784B", icon: "running", to: "/team-select"},
    "crew": {color: "#FF3B30", icon: "megaphone", to: "/event-select"},
    "contactPerson": {color: "#25D366", icon: "user-crown", to: "/home"},
  }


  const handleRoleSelect = async (role) => {
    try {
      // Send POST request with the selected role
      const response = await fetch('/api/role', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
        },
        body: JSON.stringify({ role }) // Send the selected role
      });
  
      if (!response.ok) {
        throw new Error('Failed to select role');
      }
  
      const data = await response.json();
      console.log('Role selected:', data);

      window.location.href = role_dict[role].to;
  
    } catch (error) {
      console.error('Error selecting role:', error);
    }
  };
  

  return (
    <Screen>
      <div className='top'>

        <Card>
            <Title my="4">{t('selectYourRole')}</Title>
            <Button 
                theme="outline-secondary" 
                p="2" 
                to="/login" 
                styles={{ position: 'absolute', right: '1.3rem', top: '1.7rem', backgroundColor:'inherit' }} 
                onClick={logout}
            >
                <Icon name="sign-out-alt" theme='secondary' />
            </Button>
        </Card>


        <div>
            {userInfo?.roles?.map((role, index) => (
                <Card key={index} onClick={() => handleRoleSelect(role)} style={{ backgroundColor: role_dict[role].color, color: 'white', cursor: 'pointer' }} >
                    
                    <Icon name={role_dict[role].icon} theme='light' style={{ fontSize: '9rem' }} />

                    <div style={{ fontSize: '2rem', fontWeight: 'bold', marginTop: '-2rem' }} className='mb-2'>
                        {t(role)}
                    </div>

                </Card>
            ))}
        </div>

        


      </div>
    </Screen>
  );
};

export default Page;
import React from 'react';
import { useState, useEffect } from 'react';
import { fetchUserInfo, formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import CoverImage from '../../components/images/CoverImage';
import NavBar from '../../components/common/NavBar';
import Screen from '../../components/common/Screen';
import Button from '../../components/elements/Button';
import Icon from '../../components/common/Icon';
import Image from '../../components/images/Image';
import Title from '../../components/common/Title';
import Center from '../../components/css/Center';
import Base from '../Base';

const styles = {
  h3: {
    textAlign: 'left'
  },
};

const Home = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/home', setUserInfo, setLoading, setError, []);
  }, []);

  return (
    <Base>

      
      <div>

          {userInfo?.role === 'player' && 
          <div className='d-flex flex-column justify-content-center align-items-center mt-2 mb-2'>
            <Image to="/team" size='22rem' className='my-1 outline-light' onClick={() => localStorage.setItem('otherTeamId', userInfo.team_id)} url={userInfo?.team_photo_url ? userInfo?.team_photo_url : undefined}>
              <div className="p-2 image-caption">
                <Title heading="5" my="0">{loading ? <p className="placeholder-glow"><span className="placeholder col-8"></span></p> : userInfo.team_name}</Title> 
                {loading ? <p className="placeholder-glow"><span className="placeholder col-6"></span></p> : userInfo.company_name}

              </div>
              <div style={{ position: 'absolute', top: '0.5rem', right: '0.5rem'}}>
                  <Icon name="choose" className="m-2" theme="secondary" style={{ fontSize: '2rem'}}  />
              </div>
            </Image>
          </div>
          }

          {userInfo?.role === 'crew' && 
          <Card className='d-flex flex-column justify-content-center align-items-center mt-2'>
            <Title heading="1" my='0' className='underline-secondary mb-2 mt-1' >{t('scoreEntry')} </Title>
            <Image to="/crew-battles" size='22rem' className='my-1' url={userInfo?.activity_photo_url ? userInfo?.activity_photo_url : undefined} >
              <div className="p-2 image-caption">
                <Title heading="6" my="0">{userInfo.activity_name ? userInfo.activity_name : t('noBattleYet')}</Title> 
              </div>
            </Image>
          </Card>
          
          }

          <Card to="/event" className='d-flex flex-row align-items-center justify-content-center' >
              <Image url={userInfo && userInfo.photo_url} className="circle" size='6rem' />
              <div className='d-flex flex-column ms-2 text-start'>
                  <Title heading='6' my="0">
                      {loading ? (
                          <p className="placeholder-glow">
                              <span className="placeholder col-6"></span>
                          </p>
                      ) : userInfo.name}
                  </Title>
                      {loading ? (
                          <p className="placeholder-glow">
                              <span className="placeholder col-4"></span>
                          </p>
                      ) : formatDate(userInfo.date)}
              </div>
          </Card>





      <div className='row px-3  justify-content-center g-2'>
        
          
      <div className="col-3 text-center">
        <Button theme="light" mb="0" p="3" to="/teams">
          <div className="d-flex flex-column align-items-center">
            <Icon name="employees" theme="secondary" />
            <label className="mt-2 fs-7">{t('teams')}</label>
          </div>
        </Button>
      </div>

      <div className="col-3 text-center">
        <Button theme="light" mb="0" p="3" to="/companies">
          <div className="d-flex flex-column align-items-center">
            <Icon name="building" theme="secondary" />
            <label className="mt-2 fs-7">{t('companies')}</label>
          </div>
        </Button>
      </div>

      <div className="col-3 text-center">
        <Button theme="light" mb="0" p="3" to="/battles">
          <div className="d-flex flex-column align-items-center">
            <Icon name="running" theme="secondary" />
            <label className="mt-2 fs-7">{t('challenges')}</label>
          </div>
        </Button>
      </div>

      {userInfo?.role === 'player' && 
        <div className="col-3 text-center">
          <Button theme="light" mb="0" p="3" to="/planning">
            <div className="d-flex flex-column align-items-center">
              <Icon name="calendar-lines" theme="secondary" />
              <label className="mt-2 fs-7">{t('planning')}</label>
            </div>
          </Button>
        </div>
      }

      {userInfo?.role === 'player' && 
        <div className="col-3 text-center">
          <Button theme="light" mb="0" p="3" to="/scores">
            <div className="d-flex flex-column align-items-center">
              <Icon name="hundred-points" theme="secondary" />
              <label className="mt-2 fs-7">{t('scores')}</label>
            </div>
          </Button>
        </div>
      }

      <div className="col-3 text-center">
        <Button theme="light" mb="0" p="3" to="/leaderboard">
          <div className="d-flex flex-column align-items-center">
            <Icon name="ranking-star" theme="secondary" />
            <label className="mt-2 fs-7">{t('leaderboard')}</label>
          </div>
        </Button>
      </div>


          

          {/* <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/houses">
              <Icon name="house-flag" />
            </Button>
            <label className='my-2'>{t('houses')} </label>
          </div> */}
          {/* <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/faq">
              <Icon name="question" />
            </Button>
            <label className='my-2'>{t('faq')}</label>
          </div>
          <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/map">
              <Icon name="map" />
            </Button>
            <label className='my-2'>{t('map')}</label>
          </div>
          <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/rules">
              <Icon name="megaphone" />
            </Button>
            <label className='my-2'>{t('rules')}</label>
          </div> */}
          <div className='col-3 text-center '>
            <Button theme='light' mb="0" p="3" className="" to="/awards">
              <div className="d-flex flex-column align-items-center">
                <Icon name="medal" theme='secondary' />
                <label className='mt-2 fs-7'>{t('awards')}</label>
              </div>
            </Button>
          </div>
      </div>
      </div>



    </Base>
  );
};

export default Home;
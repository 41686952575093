import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { fetchUserInfo } from '../../utils';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import SameLine from '../../components/css/SameLine';
import Left from '../../components/css/Left';
import Icon from '../../components/common/Icon';
import { Link } from 'react-router-dom';
import Button from '../../components/elements/Button';
import Center from '../../components/css/Center';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/cheers', setUserInfo, setLoading, setError, ['otherTeamId']);
  }, []);


  return (
    <Base activeNav="home">
      <Card>
      <div style={{position: 'absolute'}} className='p-2' >
          <Link to='/team' style={{color: 'inherit', textDecoration: 'none'}}>
            <Icon name='angle-left' />
          </Link>
        </div>

        <Title my='1'>{t('votes')}</Title>
               {userInfo && userInfo.cheers.length > 0 && <>{userInfo.cheers[0].team_name}</>}
      </Card>

        {userInfo && userInfo.cheers && userInfo.cheers.length > 0 && (
          <>
            {userInfo.cheers.map((cheer) => (
              
              <Card mx='2' mb="2" className="row d-flex align-items-center">
                <div className='col-2'>
                  <Image url={cheer.photo_url ? cheer.photo_url : undefined} size="3rem" className="me-2" />
                </div>
              
                <div className='col' style={{ textAlign: 'left' }}>
                  <div><b>{cheer.name}</b></div>
                  <div>{cheer.message}</div>
                </div>
              </Card>

            ))}
          </>
        )}

        {userInfo && (userInfo.cheers.length === 0) &&  (
          <Card p='3'>
            <Center><Image url={require('./../../assets/images/not-found.png')} className='my-4' /></Center>
            <Title heading="4" my="2" className='mb-3'>{t('noVotesYet')} </Title>
            <Button mb='2' to='/team' >{t('backToTeam')} </Button>

          </Card>
        )}


    </Base>
  );
};

export default Page;
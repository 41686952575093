import React from 'react';

const styles = {
  ImageContainer: {
    width: '100%',
    borderRadius: '6px',
    backgroundImage: 'url(./image.jpg)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
};

const CoverImage = ({
  image = 'https://assets.api.uizard.io/api/cdn/stream/8646f684-df09-4f4b-97f7-e5426a1fdaeb.jpg',
  height = '8rem' // Default height
}) => {
  
   // Add a cache-busting timestamp to the URL
   const cacheBustedUrl = `${image}?t=${new Date().getTime()}`;
  return (
    <div style={{
      ...styles.ImageContainer,
      backgroundImage: `url(${cacheBustedUrl})`,
      height: height,
    }} />
  );
};

export default CoverImage;
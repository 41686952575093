import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Center from '../../components/css/Center';
import { Link } from 'react-router-dom';
import Left from '../../components/css/Left';
import SameLine from '../../components/css/SameLine';
import Back from '../../components/common/Back';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/awards', setUserInfo, setLoading, setError, []);
  }, []);

  return (
    <Base activeNav="home">
      <Back to='/home' />
        <Title>{t('awards')}</Title>

        {userInfo && userInfo.awards && (
          <>
            {userInfo.awards.map((award) => (
              
                <Card mx='3' mb="2" 
                  {...(award.winner_type === 'team' ? { 
                    to: "/team", 
                    onClick: () => localStorage.setItem('otherTeamId', award.winner_id) 
                  } : award.winner_type === 'company' ? { 
                    to: "/company", 
                    onClick: () => localStorage.setItem('otherCompanyId', award.winner_id) 
                  } : {})}
                >

                      <SameLine className="row">
                      
                        <div className='col-4'>
                        <Image url={award.winner_photo_url ? award.winner_photo_url: require('./../../assets/images/trophy.png')} size="7rem" className="me-2" contain={award.winner_type === 'company'} />
                        </div>

                        <div className='col-8'>
                          <Title heading='5' my='2'>{award.name.replace(/(?!^)([A-Z])/g, ' $1')}</Title>
                          {award.winner_name} 
                          <br />
                          {award.company_name}
                        </div>
                      </SameLine>
                    
                </Card>
            ))}
          </>
        )}

    </Base>
  );
};

export default Page;
import React from 'react';

const Left = ({ children, className, style }) => {
  return (
    <div className={`d-flex justify-content-start align-items-center ${className}`} style={style}>
      {children}
    </div>
  );
};

export default Left;

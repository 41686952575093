import React, { useState, useEffect, useTransition } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppRoutes from './Routes';

import './assets/styles/App.css';
import './assets/styles/Bootstrap.css';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;

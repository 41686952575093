import React from 'react';

const Loading = (
  size='6',
  loading=true,
  children
) => (
  <>
  {loading ?
  <div className="placeholder-glow">
    <span className={`placeholder col-${size}`}>
    </span>
  </div> : children}
  </>
);

export default Loading;
import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Center from '../../components/css/Center';
import Left from '../../components/css/Left';
import { Link } from 'react-router-dom';
import Icon from '../../components/common/Icon';
import Button from '../../components/elements/Button';
import Flag from 'react-flagpack';
import 'react-flagpack/dist/style.css'


const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/public-profile', setUserInfo, setLoading, setError, ["otherUserId"]);
  }, []);


  return (
    
    <Base activeNav="home">
    
  {userInfo && (
      userInfo.is_public ? (
        <>
          <Card className='row'>
            <div className='d-flex align-items-center mt-1'>
              <div className='col-4'>
                <Image url={userInfo.photo_url ? userInfo.photo_url : require('./../../assets/images/user.png')} className='circle' size='4.5rem' />
              </div>
              
              <div className='col row' style={{fontWeight: 'bold'}}>
                <div className='col-4'>
                  <div className='my-2'>
                    {userInfo.battle_count} <br />
                    {t('challenges')}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='my-2'>
                    {userInfo.team_count} <br />
                    {t('teams')}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='my-2'>
                    {userInfo.award_count} <br />
                    {t('awards')}
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-between align-items-center' >
            <div className='text-start'>
            <div className='mt-2' style={{fontWeight: 'bold'}} >
              {userInfo.full_name}

              
            </div>  
            {userInfo.bio && (<div className='text-secondary mb-1'>{userInfo.bio} </div>)}

            {userInfo.instagram && (
              <Link 
                to={userInfo.instagram.startsWith('http') ? userInfo.instagram : `https://instagram.com/${userInfo.instagram}`}
                style={{ textDecoration: 'none' }} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <Icon name="instagram" pack="brands" className="mx-1" style={{ color: '#C13584' }} />
              </Link>
            )}

            {userInfo.twitter && (
              <Link 
                to={userInfo.twitter.startsWith('http') ? userInfo.twitter : `https://twitter.com/${userInfo.twitter}`}
                style={{ textDecoration: 'none' }} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <Icon name="twitter" pack="brands" className="mx-1" style={{ color: '#1DA1F2' }} />
              </Link>
            )}

            {userInfo.facebook && (
              <Link 
                to={userInfo.facebook.startsWith('http') ? userInfo.facebook : `https://facebook.com/${userInfo.facebook}`}
                style={{ textDecoration: 'none' }} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <Icon name="facebook" pack="brands" className="mx-1" style={{ color: '#1877F2' }} />
              </Link>
            )}

            {userInfo.linkedin && (
              <Link 
                to={userInfo.linkedin.startsWith('http') ? userInfo.linkedin : `https://linkedin.com/in/${userInfo.linkedin}`}
                style={{ textDecoration: 'none' }} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <Icon name="linkedin" pack="brands" className="mx-1" style={{ color: '#0077B5' }} />
              </Link>
            )}

            
            {userInfo.phone && (
              <Link 
                to={`tel:${userInfo.phone}`} 
                style={{ textDecoration: 'none' }} 
              >
                <Icon name="whatsapp" pack="brands" className="mx-1" style={{ color: '#25D366' }} />
              </Link>
            )}
            </div>
            {userInfo.country &&
              <div className=''>
                <Flag
                  code={userInfo.country}
                  size="l"
                  hasBorderRadius
                  hasBorder={false}
                  hasDropShadow={false}
                />
                </div>
              }
            </div>
            


          </Card>
    
          {/* <Card className='btn-secondary row'>
            <div className='col-4'>
              <Title heading="6" my="2">
                {userInfo.battle_count} <br />
                {t('challenges')}
              </Title>
            </div>
            <div className='col-4'>
              <Title heading="6" my="2">
                {userInfo.team_count} <br />
                {t('teams')}
              </Title>
            </div>
            <div className='col-4'>
              <Title heading="6" my="2">
                {userInfo.award_count} <br />
                {t('awards')}
              </Title>
            </div>
          </Card> */}
    
          <Card>
    
            <div className='row d-flex justify-content-center g-0'>
              {userInfo.teams.map((team) => (
                <div className='col d-flex justify-content-center mb-3' key={team.id}>
                  <Link to="/team" onClick={() => localStorage.setItem('otherTeamId', team.id)} style={{ textDecoration: 'none' }}>
                    <Image size="9rem" url={team.photo_url ? team.photo_url : undefined}>
                      <Center>
                        <div className="image-top px-3 py-1">
                          <b>
                            <Icon name="calendar" pack='sr' className="me-2" theme='light' />
                            {new Date(team.date).getFullYear()}
                          </b>
                        </div>
                      </Center>
    
                      <div className="p-2 image-caption">
                        <Title heading="6" my="0">{team.name}</Title> 
                        {team.company_name}
                      </div>
                    </Image>
                  </Link>
                </div>
              ))}
            </div>
          </Card>
        </>
      ) : (
        <Card className='px-4'>
          <Center><Image url={require('./../../assets/images/locked-user.png')} className='my-4' /></Center>
          <Title heading="4" my="2" className='mb-3'>{t('profileNotPublic')} </Title>
          <p className='text-secondary'>{t('profileNotPublicDesc')} </p>
          <Button theme='primary' to="/profile" className='mt-3'>{t('goToYourProfile')}</Button>
        </Card>
      )
    )}
  
    </Base>
  );
};

export default Page;
import React from 'react';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import { useEffect, useState } from 'react';
import { fetchUserInfo, handleImageUpload } from '../../utils';
import Button from '../../components/elements/Button';
import SameLine from '../../components/css/SameLine';
import Image from '../../components/images/Image';
import InputField from '../../components/forms/InputField';
import SelectField from '../../components/forms/SelectField';
import CheckboxField from '../../components/forms/CheckboxField';
import Center from '../../components/css/Center';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/common/Icon';
import TextArea from '../../components/forms/TextArea';
import Flag from 'react-flagpack';
import Select from 'react-select';
import ImageInput from '../../components/forms/ImageInput';
import Toast from '../../components/forms/Toast';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false); 
  const [imageUrl, setImageUrl] = useState(userInfo?.photo_url); // Store uploaded image URL

  useEffect(() => {
    fetchUserInfo('/edit-profile', setUserInfo, setLoading, setError);
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    try {
      const response = await fetch('/api/edit-profile', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
      });
  
      // Check if the response is OK (status code 200-299)
      if (response.ok) {
          const data = await response.json();
          console.log('Success:', data.message);
          setError(null); // Reset error on success
          navigate('/profile'); // Navigate only when successful
      } else {
          // Handle non-200 status codes
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to update profile'); // Create an error for the catch block
      }
  
  } catch (error) {
      setError(error.message); // Set error message in state
      setShowToast(true); // Show the toast notification when there's an error
      console.error('Error:', error.message);
  }
  
  };
    const formatInputDate = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (`0${d.getMonth() + 1}`).slice(-2);
      const day = (`0${d.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    };

    let countryOptions = [];
    
    if (userInfo && userInfo.countries) {
      countryOptions = userInfo.countries.map((country) => ({
        value: country.id,
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Flag code={country.iso_code} className='me-2' hasBorder={false} />
            {country.name}
          </div>
        ),
      }));
    }
  
    
    

  return (
    <form onSubmit={onSubmit}> 
    <Screen color='white' p="3" >
        <div className="top">
        <Title>{t('yourProfile')}</Title>
      
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className='mb-4'>

        <ImageInput 
          onUpload={(file) => handleImageUpload(file, '/api/upload-user-image', setError, setShowToast)} 
          caption={t('editPicture')} 
          url={userInfo?.photo_url ? userInfo.photo_url : require('./../../assets/images/user.png')}  
        />

        <div className='text-secondary mt-1'>
          <Icon name='info' style={{ color:'red'}} className='me-1' />
          {t('timeForChangesInfo')}
        </div>

        </div>

        
      
        {userInfo && (
          <>
            <div className="row">
              <InputField type="text" name="first_name" label={t("firstName")} placeholder={t("firstName")} className='col-6' value={userInfo.first_name} required maxLength={30} />
              <InputField type="text" name="name" label={t("lastName")} placeholder={t("lastName")} className='col-6' value={userInfo.name} required maxLength={30} />
            </div>
            <TextArea type="text" name="bio" label={t("bio")} placeholder={t("bio")} value={userInfo.bio} />
            <InputField type="date" name="date_of_birth" label={t("dateOfBirth")} placeholder={t("dateOfBirth")} value={formatInputDate(userInfo.date_of_birth)} />
            <SelectField name="is_male" label={t("gender")} value={userInfo.is_male}>
              <option value="true">
                <Icon name='male' style={{color:'#002335'}} /> 
                {t("male")}
              </option>
              
              <option value="false">
                <Icon name='female' style={{color:'#002335'}} /> 
                {t("female")}
              </option>
            </SelectField>
            <div className="mb-3 form-group text-start">
              <label htmlFor="country_id" className='text-start'>{t("country")}</label>
              <Select
                id="country_id"
                name="country_id"
                value={countryOptions.find(option => option.value === userInfo.country_id)}
                onChange={(selectedOption) => {
                  setUserInfo((prevState) => ({
                    ...prevState,
                    country_id: selectedOption.value,
                  }));
                }}
                options={countryOptions}
                className='form-control'
              />
            </div>

            <CheckboxField name="is_public" label={t("publicProfile")} checked={userInfo.is_public} />
          </>
        )}
        <Toast showToast={showToast} setShowToast={setShowToast} message={t(error)} />
      </div>

      <div className="bottom">
        <div className="px-3">
          <hr className="mb-3" />
          <Button type='submit' className='mb-3'>
            {t("done")}
          </Button>
        </div>
      </div>
    </Screen>
    </form>
  );
};

export default Page;
import React from 'react';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Image from '../../components/images/Image';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';
import InputField from '../../components/forms/InputField';
import Button from '../../components/elements/Button';
import { useNavigate } from 'react-router-dom';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/connections', setUserInfo, setLoading, setError);
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    const response = await fetch('/api/connections', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(values)
    });

    const data = await response.json();
    if (response.ok) {
        navigate('/profile');
    } else {
        console.error(data.error);
    }
};

  return (
    <form onSubmit={onSubmit}> 
    <Screen color='white' p="3" >
        <div className="top">
        <Title>{t('socials')}</Title>
      
        {userInfo && (
          <>
            <InputField 
              type="text" 
              name="instagram" 
              label={t("instagram")} 
              placeholder={t("username")} 
              value={userInfo.instagram} 
              icon={<Icon name='instagram' pack='brands' style={{ color: '#C13584' }} />}
              style={{ borderColor: '#C13584', color: '#C13584', '::placeholder': { color: '#C13584' } }}
            >
              @
            </InputField>
            <InputField 
              type="text" 
              name="twitter" 
              label={t("twitter")} 
              placeholder={t("username")} 
              value={userInfo.twitter} 
              icon={<Icon name='twitter' pack='brands' style={{ color: '#1DA1F2' }} />}
              style={{ borderColor: '#1DA1F2', color: '#1DA1F2', '::placeholder': { color: '#1DA1F2' } }}
            >
              @
            </InputField>
            <InputField 
              type="text" 
              name="facebook" 
              label={t("facebook")} 
              placeholder={t("username")} 
              value={userInfo.facebook} 
              icon={<Icon name='facebook' pack='brands' style={{ color: '#1877F2' }} />}
              style={{ borderColor: '#1877F2', color: '#1877F2', '::placeholder': { color: '#1877F2' } }}
            >
              @
            </InputField>
            <InputField 
              type="text" 
              name="linkedin" 
              label={t("linkedin")} 
              placeholder={t("username")} 
              value={userInfo.linkedin} 
              icon={<Icon name='linkedin' pack='brands' style={{ color: '#0077B5' }} />}
              style={{ borderColor: '#0077B5', color: '#0077B5', '::placeholder': { color: '#0077B5' } }}
            >
              @
            </InputField>      
            <InputField 
              type="text" 
              name="phone" 
              label={t("whatsapp")} 
              placeholder={t("phoneNumber")} 
              value={userInfo.phone} 
              icon={<Icon name='whatsapp' pack='brands' style={{ color: '#25D366' }} />}
              style={{ borderColor: '#25D366', color: '#25D366', '::placeholder': { color: '#25D366' } }}
            >
              #
            </InputField>

          </>
        )}
      </div>

      <div className="bottom">
        <div className="px-3">
          <hr className="mb-3" />
          <Button type='submit' className='mb-3'>
            {t("done")}
          </Button>
        </div>
      </div>
    </Screen>
    </form>
  );
};

export default Page;
import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Button from '../../components/elements/Button';
import SameLine from '../../components/css/SameLine';
import Image from '../../components/images/Image';
import Loading from '../../components/common/Loading';
import Center from '../../components/css/Center';
import Icon from '../../components/common/Icon';
import { Link, useNavigate } from 'react-router-dom';
import Left from '../../components/css/Left';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [showToast, setShowToast] = useState(false); // State for showing the toast
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserInfo('/team', setUserInfo, setLoading, setError, ["otherTeamId"]);
  }, [reload]);

  const setChallenged = async (challenged) => {
    try {
      const response = await fetch('/api/put-challenge', {
        method: 'PUT', // Use PUT method
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ challenged_id: localStorage.getItem('otherTeamId'), challenged: challenged })
      });

      if (!response.ok) {
        const data = await response.json(); // Get error message from response
        throw new Error(data.message || 'Network response was not ok');
      }

      const data = await response.json();
      console.log('Success:', data.message);
      setError(null); // Reset error on success
      setReload(prev => !prev); // Toggle reload state to force re-render
    } catch (error) {
      setError(error.message); // Set error message in state
      setShowToast(true); // Show the toast notification when there's an error
      console.error('Error:', t(error.message));
    }
  };

  const leaveTeam  = async () => {
  try {
    const response = await fetch('/api/leave-team', {
      method: 'PUT', // Use PUT method
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify()
    });

    if (!response.ok) {
      const data = await response.json(); // Get error message from response
      throw new Error(data.message || 'Network response was not ok');
    }

    const data = await response.json();
    console.log('Success:', data.message);
    setError(null); // Reset error on success
    navigate('/team-select')
  } catch (error) {
    setError(error.message); // Set error message in state
    setShowToast(true); // Show the toast notification when there's an error
    console.error('Error:', t(error.message));
  }
};

  return (
    <Base activeNav="home">
      

      <Card>
        <Center>
          <Image className={'mb-3 circle'} url={userInfo && userInfo.picture ? userInfo.picture : undefined} />
        </Center>
        <Title my="0" heading="5">{loading ? <Loading /> : userInfo.name}</Title>

        <div className="mb-2 text-secondary"><i>{loading ? <Loading /> : userInfo.slogan}</i></div>

        <Center>
          {userInfo && userInfo.company_id &&
            <Button theme='light' styles={{}} p="1" onClick={() => localStorage.setItem('otherCompanyId', userInfo.company_id)} to="/company" className="my-2 px-2">
              <SameLine>
                {loading ? <Loading size="0" /> : <Image url={userInfo.company_picture} size="2rem" contain className='me-1' />}
                {loading ? <Loading /> : userInfo.company_name}
              </SameLine>
            </Button>
          }
        </Center>

        <Center>
          {userInfo?.role === 'player' && localStorage.getItem('otherTeamId') !== userInfo.user_team_id && new Date() < new Date(userInfo.closing_date) &&
            <>
              {userInfo.challengers && Object.values(userInfo.challengers).some(challenger => challenger.id === userInfo.user_team_id) ? (
                <Button theme='secondary' styles={{}} p="2"  className="px-2" onClick={() => setChallenged(false)}>
                  <SameLine>
                    <Icon name="megaphone" className="me-2" theme='light' />
                    {t('unchallengeOther')}
                  </SameLine>
                </Button>
              ) : (
                <Button theme='light' styles={{}} p="2"  className="px-2" onClick={() => setChallenged(true)} >
                  <SameLine>
                    <Icon name="megaphone" className="me-2" theme='secondary' />
                    {t('challengeOther')}
                  </SameLine>
                </Button>
              )}
            </>
        }
        {/* Bootstrap Toast Notification */}
          <div className="toast-container p-3"  >
            <div className={`toast ${showToast ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header">
                <Icon name="exclamation" theme="secondary" className="me-2" />
                <strong className="me-auto">{t(error)}</strong>
                <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button>
              </div>
            </div>
          </div>
        </Center>

        <Center>

          {userInfo && userInfo.house_id && 
            <Button 
              styles={{}} 
              theme='light' 
              onClick={() => {
                localStorage.setItem('selectedHouseId', userInfo.house_id);
              }}
              to="/house"
              className='mx-1'
            >
              <Icon name="house-flag" style={{color: userInfo.house_color}} />
            </Button>
          }

          {userInfo && Object.values(userInfo.challengers).some(challenger => challenger.id === userInfo.user_team_id) && (
            <div style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
              <Icon name="megaphone" theme='secondary' pack='sr' />
            </div>
          )}

          {userInfo && userInfo.song_url && 
            <Button 
              styles={{}} 
              theme='light' 
              to={`${userInfo.song_url}`}
              className='mx-1'
            >
              <Icon name="music-alt" />
            </Button>
          }

          
          {localStorage.getItem('otherTeamId') === userInfo?.user_team_id && new Date() < new Date(userInfo.closing_date) && (
            <Button styles={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} theme='light' to="/edit-team" className='mx-1'>
              <Icon name="settings" theme='secondary' pack='sr' />
            </Button>
          )}
          
        </Center>
        
       
      </Card>

      <Card {...(localStorage.getItem('otherTeamId') === userInfo?.user_team_id ? {to: "/players"} : {})}>
        {userInfo && localStorage.getItem('otherTeamId') === userInfo.user_team_id && new Date() < new Date(userInfo.closing_date) && (
          <div style={{ textDecoration: 'none', color: 'inherit', position: 'absolute', right:'1rem' }}>
            <p className='text-secondary'>{t('manage')} <Icon name='angle-right' /></p>
          </div>
        )}

        <Left><Title my='1' className='mb-2'>{t('players')}</Title></Left>
          
          {userInfo && userInfo.players && userInfo.players.length > 0 ? (
          <div className='row g-1 justify-content-center' >
            {userInfo.players.map((player) => (
              
                <Card p='0' mx='1' mb="1" className={`col px-1 py-2 ${player.id === userInfo.user_id ? 'btn-primary' : ''}`} >
                    <Link to="/public-profile" onClick={() => localStorage.setItem('otherUserId', player.id)} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Center>
                      <Image url={player.photo_url ? player.photo_url : require('./../../assets/images/user.png')} size='4.5rem' className='circle mb-1' />
                    </Center>

                    {player.is_captain && (<Icon name='user-crown' pack='sr' className='me-1' theme='secondary' />)}
                   <b>{player.full_name}</b>

                  </Link>
                </Card>
            ))}
            
          </div>
        ): (
          <p>{t('noPlayersYet')} </p>
        )
      }
          
      </Card>

      <Card >
        <Left><Title my='1' className='mb-3'>{t('competition')}</Title></Left>
        <div className='row g-2'>
        {userInfo && userInfo.joker && 
         <div className='col'>
         <Link to={userInfo.joker.name ? "/battle" : "/battles"} onClick={() => localStorage.setItem('selectedBattleId', userInfo.joker.id)} style={{ textDecoration: 'none' }}>
          <Image url={userInfo.joker.photo_url} size='9.5rem'>
            <Center>
              <div className="image-top px-3 py-1">
                <Icon name='star' pack='sr' className="me-2" theme='light' />
                {t('joker')}
              </div>
            </Center>
            <div className="p-2 image-caption">
              <Title heading="6" my="0">{userInfo.joker.name ? userInfo.joker.name : t('noJokerSelected')}</Title> 
            </div>
          </Image>
          </Link>
          </div>
        }
        {userInfo && userInfo.points &&
          <Card className='col' mb='0' mx='0' p='1'>
            <Link to='/leaderboard' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Icon name="chart-simple" className="active" theme="secondary" style={{'fontSize': '44px'}} />
            <Title heading="5" my="1" style={{  }}>{userInfo.points.points} {t('points')} </Title>
            <p className='text-secondary'>
              {userInfo.points.battle_points} {t('challenge')} <br />
              {userInfo.points.extra_points}  {t('extra')}
            </p> 
            </Link>
          </Card>
        }

        </div>
        
      </Card>

      {userInfo && userInfo.bio &&
      (<Card>
        <Left><Title my='1' className='mb-3'>{t('bio')}</Title></Left>

        <p className='text-secondary'>{userInfo && userInfo.bio}</p>
      </Card>)
      }

      <Card to="/rivals" onClick={() => localStorage.setItem('rivalsId', userInfo.id)} >
        <div style={{ textDecoration: 'none', color: 'inherit', position: 'absolute', right:'1rem' }}>
          <p className='text-secondary'>{t('seeAll')} <Icon name='angle-right' /></p>
        </div>

        <Left><Title my='1' className='mb-3'>{t('rivals')}</Title></Left>

        {userInfo && (userInfo.challenged || userInfo.challengers) && (userInfo.challenged.length > 0 || userInfo.challengers.length > 0) ? (
            <div>
              {userInfo.challenged.map((rival, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', width: '100%' }} className='mb-1'>
                  <Image url={rival.photo_url ? rival.photo_url : undefined} size='2rem' className='circle mx-2' />
                  <div style={{textAlign: 'left' }}>
                    <p style={{ margin: 0 }}>{rival.name}</p>
                    <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className='text-secondary'>
                      {rival.message}
                    </p>
                  </div>
                </div>
              ))}
              {userInfo.challengers.map((rival, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', width: '100%' }} className='mb-1'>
                  <Image url={rival.photo_url ? rival.photo_url : undefined} size='2rem' className='circle mx-2' />
                  <div style={{textAlign: 'left' }}>
                    <p style={{ margin: 0 }}>{rival.name}</p>
                    <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className='text-secondary'>
                      {rival.message}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>{t('noRivalsYet')} </p>
          )}
      </Card>

      <Card to="/cheers">
        <div style={{ textDecoration: 'none', color: 'inherit', position: 'absolute', right:'1rem' }}>
          <p className='text-secondary'>{t('seeAll')} <Icon name='angle-right' /></p>
        </div>

        <Left><Title my='1' className='mb-3'>{t('votes')}</Title></Left>
        {userInfo && userInfo.cheers && userInfo.cheers.length > 0 ? (
          <div>
            {/* Render cheers here */}
            {userInfo.cheers.map((cheer, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <Image url={cheer.photo_url ? cheer.photo_url : undefined} size='2rem' className='circle mx-2' />
                <div style={{  textAlign: 'left' }}>
                  <p style={{ margin: 0 }}>{cheer.name}</p>
                  <p style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }} className='text-secondary'>
                    {cheer.message}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>{t('noVotesYet')} </p>
        )}

      </Card>
        
      {userInfo && localStorage.getItem('otherTeamId') === userInfo.user_team_id && new Date() < new Date(userInfo.closing_date) && (
      <Button styles={{}} theme='light' onClick={() => leaveTeam()}>
        <Icon name='exit' theme='secondary' className='me-2' />
        {t('leaveTeam')}
      </Button>
      )}

      
    </Base>
  );
};

export default Page;
import React from 'react';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import { useEffect, useState } from 'react';
import { fetchUserInfo, handleImageUpload } from '../../utils';
import Button from '../../components/elements/Button';
import SameLine from '../../components/css/SameLine';
import Image from '../../components/images/Image';
import InputField from '../../components/forms/InputField';
import Center from '../../components/css/Center';
import { Link, useNavigate } from 'react-router-dom';
import TextArea from '../../components/forms/TextArea';
import Icon from '../../components/common/Icon';
import ImageInput from '../../components/forms/ImageInput';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false); 

  useEffect(() => {
    fetchUserInfo('/edit-team', setUserInfo, setLoading, setError, []);
  }, []);

  const onSubmit = async (event) => {
      event.preventDefault();
      
      const formData = new FormData(event.target);
      const values = Object.fromEntries(formData.entries());
  
      const token = localStorage.getItem('token'); // Retrieve the token from local storage
  
      try {
        const response = await fetch('/api/edit-team', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(values)
        });
    
        const data = await response.json();
    
        // Check if the response status is OK (status code 200-299)
        if (response.ok) {
            navigate('/team'); // Navigate only if the request was successful
        } else {
            // Handle non-200 status codes
            console.error(t(data.message || 'Failed to edit team'));
            throw new Error(data.message || 'Failed to edit team');
        }
    } catch (error) {
        console.error('Error:', error.message); // Log and handle the error
        // Optional: Handle your error state or show an error message to the user
        setError(error.message); // Optional: set an error state to display to the user
        setShowToast(true); // Optional: show a toast notification to notify the user
    }
    
  };

  return (
    <form onSubmit={onSubmit}>
    <Screen color='white' p="3" >

      <div className="top">
        <Title>{t('yourTeam')}</Title>
      
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className='mb-4'>

          <ImageInput 
            onUpload={(file) => handleImageUpload(file, '/api/upload-team-image', setError, setShowToast)} 
            caption={t('editPicture')} 
            url={userInfo?.picture ? userInfo.picture : require('./../../assets/images/user.png')}  
          />

        <div className='text-secondary mt-1'>
          <Icon name='info' style={{ color:'red'}} className='me-1' />
          {t('timeForChangesInfo')}
        </div>
        </div>
      
        {userInfo && (
          <>
            <InputField type="text" name="name" label={t("teamName")} placeholder={t("teamName")} value={userInfo.name} required />
            <InputField type="text" name="slogan" label={t("slogan")} placeholder={t("slogan")} value={userInfo.slogan} />
            <TextArea type="text" name="bio" label={t("bio")} placeholder={t("bio")} value={userInfo.bio} />
            <InputField type="text" name="song_id" label={t("song")} placeholder={t("song")} value={userInfo.song_url} />
          </>
        )}

        {/* Bootstrap Toast Notification */}
        <div className="toast-container p-3"  >
          <div className={`toast ${showToast ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
              <Icon name="exclamation" theme="secondary" className="me-2" />
              <strong className="me-auto">{t(error)}</strong>
              <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className="px-3">
          <hr className="mb-3" />
          <Button type='submit' className='mb-3'>
            {t("done")}
          </Button>
        </div>
      </div>
    </Screen>
    </form>
  );
};

export default Page;
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  Card: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0px 6px 6px rgba(0,0,0,0.1)',
    position: 'relative',
  },
};

const Card = ({
  p = '2',
  mb = '3',
  mx = '3',
  className = '',
  to, 
  onClick,
  children,
  size,
  style = {}
}) => {
  const cardStyle = {
    ...styles.Card,
    ...(size && { width: size, height: size }),
    ...style,
  };

  const card = (
    <div
      style={cardStyle}
      className={`p-${p} mx-${mx} mb-${mb} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );

  return to ? (
    <Link to={to} className='link'>
      {card}
    </Link>
  ) : (
    card
  );
};

export default Card;

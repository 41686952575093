import React, { useEffect, useState } from 'react';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Button from '../../components/elements/Button';
import { useTranslation } from 'react-i18next';
import { fetchUserInfo, formatDate } from '../../utils';
import { logout } from '../../utils';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';
import Base from '../Base';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/crew-battles', setUserInfo, setLoading, setError);
  }, []);


  const handleBattleSelect = async (scoreId) => {
    try {
      const response = await fetch('/api/crew-battles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
        },
        body: JSON.stringify({ score_id: scoreId })
      });
  
      if (!response.ok) {
        throw new Error('Failed to select battle');
      }
  
      const data = await response.json();
      console.log('Battle selected:', data);
  
      window.location.href = '/crew-score';
    } catch (error) {
      console.error('Error selecting event:', error);
    }
  };

  return (
    <Base>

        <Card>
          <Title my="4">{t('yourBattle')}</Title>
        </Card>

        
        {userInfo?.battles?.map((battle) => (
            <Card key={battle.id} onClick={() => handleBattleSelect(battle.id)} style={{ cursor: 'pointer' }}>
                <div className='justify-content-between d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                        <div>
                            <Image size="5rem" className='me-3' url={battle.activity_photo_url ? battle.activity_photo_url : undefined} />
                        </div>
                        <div className='d-flex flex-column text-start' style={{ fontSize: '15px' }}>
                            <div><b>{battle.activity_name}</b></div>
                            <div className='text-secondary'>{battle.start_time} - {battle.end_time}</div>

                        </div>
                    </div>
                    <div className='me-3 mt-1'>
                      {battle.is_correct ? (
                          <Icon name='check-circle' style={{ color: 'green', fontSize:'2rem' }} />
                        ) : (
                          <Icon name='cross-circle' style={{ color: 'red', fontSize:'2rem'  }} />
                        )}
                    </div>
                </div>
            </Card>
        ))}

    </Base>
  );
};

export default Page;
import React from 'react';

const Title = ({
    heading = "3",
    my = '4',
    align = 'center',
    children,
    className,
    style
}) => {
    const Heading = `h${heading}`;
    return (
        <Heading className={`my-${my} text-${align} ${className}`} style={style}>
            {children}
        </Heading>
    );
};

export default Title;
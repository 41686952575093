import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Card from '../../components/elements/Card';
import Button from '../../components/elements/Button';
import Screen from '../../components/common/Screen';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/common/Icon';
import Image from '../../components/images/Image';
import Center from '../../components/css/Center';
import { Link } from 'react-router-dom';
import Left from '../../components/css/Left';
import Back from '../../components/common/Back';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/players', setUserInfo, setLoading, setError, []);
  }, []);

  const updateCaptain = async (playerId) => {
    const token = localStorage.getItem('token');

    const response = await fetch('/api/update-captain', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({playerId})
    });

    const data = await response.json();
    if (response.ok) {
        window.location.reload();
    } else {
        console.error(data.message);
    }
  };

  const removePlayer = async (playerId) => {
    const token = localStorage.getItem('token');

    const response = await fetch('/api/remove-player', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({playerId})
    });

    const data = await response.json();
    if (response.ok) {
        window.location.reload();
    } else {
        console.error(data.message);
    }
  };

  return (
    <Screen p="3" >
      <div className="top">
        <Back to='/team' />
        <Title>
          {t('players')}
          {userInfo && <> ({userInfo.players.length}/{userInfo.players[0].max_players}) </>}
          
        </Title>

        {userInfo && userInfo.players && userInfo.players.length > 0 && (
          <div className='justify-content-center' >
            {userInfo.players.map((player) => (
                <div className="dropdown" key={player.id}>
                  <Button p='1' mb="1" className={`${player.is_captain ? 'btn-outline-secondary' : ''}`}  theme='light' dataBsToggle="dropdown" ariaExpanded={false} >
                      <div className='row'>
                      <div className='col-3 d-flex align-items-center'>
                        <Image url={player.photo_url ? player.photo_url : require('./../../assets/images/user.png')} size='3rem' className='circle mb-1' />
                      </div>

                      <div className='col-8 d-flex align-items-center'>
                      <Left style={{marginLeft: '-1.5rem'}}>
                          {player.is_captain && (<Icon name='user-crown' pack='sr' className='me-1' theme='secondary' />)}
                          <b>{player.full_name}</b>
                        </Left>
                      </div>
                      
                      <div className='col-1 d-flex align-items-center'>
                        <Icon name='menu-dots-vertical' theme='primary' style={{marginLeft: '-0.8rem'}} />
                      </div>

                      </div>
                      
                  </Button>
                  
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link className="dropdown-item" to='/public-profile' onClick={() => localStorage.setItem('otherUserId', player.id)}>
                        <Icon name='user' className='me-1' />
                        {t("viewProfile")} 
                        </Link>
                      </li>

                      {!player.is_captain && (
                      <>
                      <li>
                        <Link className="dropdown-item" onClick={() => updateCaptain(player.id)}>
                        <Icon name='user-crown' className='me-1' />
                        {t("promoteToCaptain")} 
                        </Link>
                      </li>

                      <li>
                        <Link className="dropdown-item" onClick={() => removePlayer(player.id)}>
                          <Icon name='cross' className='me-1' />
                          {t("removePlayer")} 
                        </Link>
                      </li>
                      </>
                      )} 

                    </ul>
                  

                </div>
            ))}
            
          </div>
        )
      }

      {userInfo && userInfo.players.length < userInfo.players[0].max_players &&  (
        <Button theme='primary' to='/add-players' className='mt-1'>
          <Icon name='plus' className='mx-2' theme='light' />
          {t('addPlayers')}
        </Button>
      )}
        
      </div>

      <div className="bottom">
        <div className="px-3">
          <hr className="mb-3" />
          <Button to="/team" className='mb-3'>
            {t("done")}
          </Button>
        </div>
      </div>
    </Screen>
    
  );
};

export default Page;
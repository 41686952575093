import React, { useState } from 'react';

const styles = {
  Input: {
    width: '100%',
    border: '1px solid #d1d5db',
    boxSizing: 'border-box',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    color: '#94a3b8',
    fontFamily: 'Poppins',
    lineHeight: '42px',
    outline: 'none',
  },
  Label: {
    color: '#6b7280',
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: '17px',
    textAlign: 'left !important',
    display: 'flex',
    alignItems: 'center',
  }
};

const InputField = ({
  placeholder = 'Write something...',
  mb = '3',
  px = '2',
  className = '',
  type = 'text',
  name = '',
  label,
  value = '',
  icon,
  children,
  required = false,
  style = {},
  labelStyle = {},
  onChange,
  maxLength = 50,
  minLength
}) => {
  const [inputValue, setInputValue] = useState(value);

  // Internal handleChange that sets the value if no external onChange is passed
  const handleChangeInternal = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className={`input-field ${className} mb-${mb} text-start`}>
      <label className="input-label" style={{ ...styles.Label, ...labelStyle }}>
        {icon && <span className="me-2">{icon}</span>}
        {label}
      </label>
      <div className="input-wrapper" style={{ position: 'relative' }}>
        <input
          style={{ ...styles.Input, ...style }}
          placeholder={placeholder}
          className={`px-${px}`}
          type={type}
          name={name}
          value={onChange ? value : inputValue}
          onChange={onChange || handleChangeInternal} 
          required={required}
          maxLength={maxLength}
          minLength={minLength}
        />
        {children && <div className="input-addon" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>{children}</div>}
      </div>
    </div>
  );
};

export default InputField;

import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import Left from '../../components/css/Left';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import CoverImage from '../../components/images/CoverImage';
import Icon from '../../components/common/Icon';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../components/images/Image';
import Center from '../../components/css/Center';
import Button from '../../components/elements/Button';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false); 
  const [showToast, setShowToast] = useState(false); 

  useEffect(() => {
    fetchUserInfo('/battle', setUserInfo, setLoading, setError, ['selectedBattleId']);
  }, [reload]); // Add reload as a dependency

  const renderStars = (val, max=5) => {
    const stars = [];
    for (let i = 0; i < max; i++) {
        stars.push(
            <Icon 
                key={i} 
                name="star" 
                theme={ i < val ? 'secondary' : 'disabled' } 
                pack='sr'
                me="1"
            />
        );
    }
    return stars;
  };

  const handleCardClick = () => {
    localStorage.setItem('selectedBattleId', userInfo.joker.id);
    setReload(prev => !prev); // Toggle reload state to force re-render
    navigate('/battle');
  };

  const setJoker = async () => {
    try {
      const response = await fetch('/api/battle', {
        method: 'PUT', // Use PUT method
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ joker_id: localStorage.getItem('selectedBattleId') })
      });

      if (!response.ok) {
        const data = await response.json(); // Get error message from response
        throw new Error(data.message || 'Network response was not ok');
      }

      const data = await response.json();
      console.log('Success:', data.message);
      setError(null); // Reset error on success
      setReload(prev => !prev); // Toggle reload state to force re-render
    } catch (error) {
      setError(error.message); // Set error message in state
      setShowToast(true); // Show the toast notification when there's an error
      console.error('Error:', t(error.message));
    }
  };

  return (
    <Base activeNav="home">
      <Card>
        <div style={{position: 'absolute'}} className='p-2' >
          <Link to='/battles' style={{color: 'inherit', textDecoration: 'none'}}>
            <Icon name='angle-left' />
          </Link>
        </div>


        {userInfo && userInfo.is_water && (
          <Button styles={{'position':'absolute', 'right': '0', 'width': '32px', 'height': '32px'}} className='circle mx-2' p="2" theme='light'>
            <Icon name="dewpoint" className="circle" pack='sr' style={{'color': '#29c5f6', 'fontSize': '12px'}} />
          </Button>
        )}
        
        <Center><Image url={userInfo && userInfo.photo_url} className='circle' /></Center>

        <Title className='mt-4 mb-1' my='0'>
          {userInfo && userInfo.name}
        </Title>

        {userInfo && userInfo.joker.id === localStorage.getItem('selectedBattleId') &&
            <div className=''>
            <Icon name="star" className='mx-2' theme='secondary' pack='sr' />
            {t('joker')}
            </div>
          }

        {userInfo &&
          <table className='table table-borderless mt-3'>
            <tr>
              <td>{t('endurance')}</td>
              <td>{renderStars(userInfo.endurance)} </td>
            </tr>
            <tr>
              <td>{t('teamwork')}</td>
              <td>{renderStars(userInfo.teamwork)} </td>
            </tr>
            <tr>
              <td>{t('guts')}</td>
              <td>{renderStars(userInfo.guts)} </td>
            </tr>
            <tr>
              <td>{t('skill')}</td>
              <td>{renderStars(userInfo.skill)} </td>
            </tr>
          </table>
        }

        <p className='text-secondary'>{userInfo && userInfo.desc}</p>

        {userInfo?.role === 'player' && userInfo.is_joker && new Date() < new Date(userInfo.closing_date) &&
          (<><Button styles={{fontWeight: 'bold'}} theme='outline-secondary' mb='2' onClick={setJoker}>
            <Icon name="star" className='mx-2' theme='' />
            {t('chooseJoker')}
          </Button>
          {userInfo.joker && <>
            <Card to='/battle' onClick={handleCardClick}>
              <div className='mb-1'><b>{t('currentJoker')}</b></div>
              <Center>
                <Image url={userInfo.joker.photo_url} className='circle mx-2' size='2rem'  />
                {userInfo.joker.name}
              </Center>
            </Card>
          </>}
          {/* Bootstrap Toast Notification */}
          <div className="toast-container p-3"  >
            <div className={`toast ${showToast ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header">
                <Icon name="exclamation" theme="secondary" className="me-2" />
                <strong className="me-auto">{t(error)}</strong>
                <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button>
              </div>
            </div>
          </div>
          </>)
          }

          
      </Card>
    </Base>
  );
};

export default Page;
import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Card from '../../components/elements/Card';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/planning', setUserInfo, setLoading, setError, []);
  }, []);

  const isOngoing = (startTime, endTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const end = new Date(endTime);
    
    return now >= start && now <= end;
  };


  return (
    <Base activeNav="home">
      
        <Card>
          <Back to='/home' />
          <Title>
            <Icon name="calendar-lines" className="me-2" theme="secondary" style={{'fontSize': '30px'}} />
            {t('planning')}
          </Title>
        </Card>

        {userInfo && userInfo.planning && userInfo.planning.map((activity, index) => {
        const isActivityOngoing = isOngoing(activity.start_time, activity.end_time);

        return (
          <Card 
            key={index}
            p="3" 
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} 
            className={`mb-1 justify-content-between d-flex align-items-center ${isActivityOngoing ? 'outline-secondary' : 'outline-primary'}`} // Conditional class
            to={activity.is_challenge ? "/score" : undefined} 
            onClick={activity.is_challenge ? () => localStorage.setItem('selectedScoreId', activity.id) : undefined}
          >
            <div className='text-start'>
              {activity.name}
              <div className='text-secondary'>
                <Icon name="clock" className="me-1" theme="secondary" />
                {activity.start_time}
              </div>
            </div>
            
            {activity.is_challenge && <b><Icon name="angle-right" /></b>}
          </Card>
        );
      })}
        
    </Base>
  );
};

export default Page;
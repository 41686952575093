import React from 'react';

const styles = {
    Screen: {
        textAlign: 'center',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 400, // Default weight
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%', // Full width of the viewport
        minHeight: '100vh', // Full height of the viewport
        maxWidth: '600px', // Limit width on larger screens
        margin: '0 auto', // Center the content horizontally
        border: '1px solid #030303',
        position: 'relative',
    },
    Bottom: {

        position: 'fixed',
        bottom: '0',
        width: '100%',
        maxWidth: '600px',
        textAlign: 'center',
        zIndex: 1000,
    }
};

const Screen = ({
    color = '#e5e7eb',
    p = '0',
    backgroundImage = null,
    children
}) => {
    const combinedStyles = {
        ...styles.Screen,
        backgroundColor: color,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
        backgroundSize: 'cover', // Ensure the background image covers the entire div
        backgroundPosition: 'center', // Center the background image
    };

    const topChildren = React.Children.toArray(children).filter(child => child.props.className === 'top');
    const centerChildren = React.Children.toArray(children).filter(child => child.props.className === 'center');
    const bottomChildren = React.Children.toArray(children).filter(child => child.props.className === 'bottom');

    return (
        <div style={combinedStyles} className={`p-${p}`}>
            
            {topChildren.length > 0 ? topChildren : <div className="top"></div>}
            {centerChildren}
            <div className='mb-5'></div> {/* So center works */}
            <div style={styles.Bottom}>
                {bottomChildren.length > 0 ? bottomChildren : <div className="bottom"></div>}
            </div>
            
        </div>
    );
};

export default Screen;
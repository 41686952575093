import React from 'react';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Image from '../../components/images/Image';
import Card from '../../components/elements/Card';
import Icon from '../../components/common/Icon';
import Center from '../../components/css/Center';
import { Link } from 'react-router-dom';
import Back from '../../components/common/Back';
import InputField from '../../components/forms/InputField';

const Companies = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/companies', setUserInfo, setLoading, setError, ["selectedEventId"]);
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  // Function to handle the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase()); // Convert to lowercase for case-insensitive matching
  };

  // Filter the companies based on the search query
  const filteredCompanies = userInfo?.companies?.filter((company) =>
    company.name.toLowerCase().includes(searchQuery)
  );

  return (
    <Base activeNav="home">
      <Card>
        <Back to='/home' />
        <Title>{t('companies')}</Title>
        </Card>

      <div className='px-3 row align-items-center'>
        <div className='col-1' style={{ marginTop: '-0.7rem' }}>
          <Icon name="search" />
        </div>

        <div className='col-11'>
          <InputField
            placeholder={t('searchCompanies')}
            name='searchCompanies'
            value={searchQuery} // Bind the input value to the state
            onChange={handleSearchChange} // Update state on input change
          >
            <Icon name="company" />
          </InputField>
        </div>
      </div>

      {filteredCompanies && filteredCompanies.length > 0 ? (
        <div className='row d-flex justify-content-center g-0'>
          {filteredCompanies.map((company) => (
            <div className='col d-flex justify-content-center mb-3' key={company.id}>
              <Link
                to="/company"
                onClick={() => localStorage.setItem('otherCompanyId', company.id)}
                style={{ textDecoration: 'none' }}
              >
                <Image
                  size="11rem"
                  url={company.photo_url ? company.photo_url : undefined}
                  contain
                  className='outline-light'
                >
                  <Center>
                    <div className="image-top px-3 py-1">
                      <b>
                        <Icon name="users-alt" pack='sr' className="me-2" theme='light' />
                        {company.team_count}
                      </b>
                    </div>
                  </Center>
                  <div className="p-2 image-caption">
                    <Title heading="6" my="0">{company.name}</Title>
                  </div>
                </Image>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <Card className="mt-1" p='3'>
          <Center><Image url={require('./../../assets/images/not-found.png')} className='my-4' /></Center>
          <Title heading='6' my="0">{t('noCompaniesFound')}</Title>
        </Card>
      )}

    </Base>
  );
};

export default Companies;
import React from 'react';
import PropTypes from 'prop-types';
import Icon from './../common/Icon'; // Added Icon component
import { Link } from 'react-router-dom';

const defaultStyles = {
  Button: {
    cursor: 'pointer',
    border: '0',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: '19px',
    outline: 'none',
  },
};

const Button = ({
  mb = '3',
  to = null,
  type = 'button',
  disabled = false,
  theme = 'secondary',
  className,
  align,
  key,
  onClick,
  children,
  p = '2',
  styles = {width: '100%'},
  ariaExpanded = false,
  dataBsToggle = null,
}) => {
  const mergedStyles = { ...defaultStyles.Button, ...styles };

  const buttonElement = (
    <button
      style={mergedStyles}
      className={` 
        mb-${mb}
        p-${p} 
        btn-${theme} 
        ${className} 
        text-${align}`}
      key={key}
      onClick={onClick}
      type={type}
      disabled={disabled}
      aria-expanded={ariaExpanded}
      data-bs-toggle={dataBsToggle}
    >
      {children}
    </button>
  );

  return to ? (
    <Link to={to} className='link'>
      {buttonElement}
    </Link>
  ) : (
    buttonElement
  );
};

Button.propTypes = {
  styles: PropTypes.object,
  key: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  align: PropTypes.string,
  p: PropTypes.string,
  mb: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
  ariaExpanded: PropTypes.bool,
  dataBsToggle: PropTypes.string,
};

export default Button;

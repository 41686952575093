import React from 'react';
import Icon from '../common/Icon';

const Toast = ({ showToast, setShowToast, message, icon='exclamation'}) => {
  return (
    <div className="toast-container p-3">
      <div className={`toast ${showToast ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          {icon && <Icon name={icon} theme="secondary" className="me-2" />}
          <strong className="me-auto">{message}</strong>
          <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button>
        </div>
      </div>
    </div>
  );
};

export default Toast;
